import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { PersonaRowType, PersonaService } from '@mc/persona-api'

import { paths } from '../../utils/paths'
import { usePersonaEvents } from '../../events'
import { useCtaPreserveQueryParams } from '../../utils/cta'
import {
  Background,
  Footer,
  NavBar,
  FaqSection,
  PlansSection,
  Testimonial,
  TestimonialsSection,
  UseCaseSection,
  Topic,
} from '../../components'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'
import { StickyTierFooter } from '../../components/StickyTierFooter'
import { useIsLoginHidden, usePersonaVideoEnabled } from '../../experiments'
import { useTiers } from '../../components/TierProvider/TierProvider'
import { Tier } from '../../types/checkout'

import { HeroSection } from './HeroSection'
import { GoalsSection } from './GoalsSection'
import { InfoSection } from './InfoSection'
import { MentorsSection } from './MentorsSection'
import { VideoSection } from './VideoSection'
import { ROW_TRACKING } from './utils'
import { getLoggedOutFaqs } from '../../utils/faq'
import styles from './LoggedOutLandingPage.module.scss'

export type LoggedOutLandingPageProps = {
  testimonials: Testimonial[]
}

export const LoggedOutLandingPage = ({
  testimonials,
}: LoggedOutLandingPageProps) => {
  const { trackPersonaLandingViewed } = usePersonaEvents()
  const { trackProductViewed, trackGoldStandardPageViewed } =
    useNonCommerceGoldStandardEvents('discovery')
  const getUrlWithQueryParams = useCtaPreserveQueryParams()
  const isPersonaVideoEnabled = usePersonaVideoEnabled()
  const isLoginHidden = useIsLoginHidden()
  const { monthlyTier, annualTier } = useTiers()

  const faqs = getLoggedOutFaqs(
    [monthlyTier, annualTier].filter((t): t is Tier => !!t),
  )

  // Gold Standard
  useEffect(() => {
    trackGoldStandardPageViewed()
    trackProductViewed()
  }, [trackProductViewed, trackGoldStandardPageViewed])

  useEffect(() => {
    trackPersonaLandingViewed()
  }, [trackPersonaLandingViewed])

  const { data } = useQuery(['homepageData'], PersonaService.getHomepage, {
    staleTime: 60000,
  })

  const mentors =
    data?.rows.find(
      (row) =>
        row.type === PersonaRowType.INSTRUCTOR_LIST &&
        row.analytics?.rowName === 'home-instructors-row',
    )?.instructorListRowItems || []

  const comingSoonMentors =
    data?.rows.find(
      (row) =>
        row.type === PersonaRowType.INSTRUCTOR_LIST &&
        row.analytics?.rowName === 'home-coming-soon-row',
    )?.instructorListRowItems || []

  const topics =
    (data?.rows.find((row) => row.type === PersonaRowType.AMA_TOPIC)
      ?.topicRowItems as Topic[]) || []

  return (
    <Background>
      <div className='container'>
        <NavBar showLogin={!isLoginHidden} source='discovery' />
        <HeroSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
        />
        <GoalsSection />
        {isPersonaVideoEnabled && <VideoSection />}
        <StickyTierFooter
          mobileShowAtPosition={300}
          desktopShowAtPosition={450}
          source='discovery'
        />
        <InfoSection />
        <UseCaseSection
          topics={topics}
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
          rowAnalytics={ROW_TRACKING.conversationStarters}
        />
        <MentorsSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
          mentors={mentors}
          comingSoonMentors={comingSoonMentors}
        />
        <TestimonialsSection
          testimonials={testimonials}
          rowAnalytics={ROW_TRACKING.testimonials}
        />
        <PlansSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
          source='discovery'
          rowAnalytics={ROW_TRACKING.plans}
        />
        <FaqSection faqs={faqs} rowAnalytics={ROW_TRACKING.faqs} />
        <Footer className={styles.footer} />
      </div>
    </Background>
  )
}
