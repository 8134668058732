import React from 'react'
import Link from 'next/link'

import { useTranslation } from '@mc/i18n'
import { Button } from '@mc/design-system'

import { useCtaToPurchaseUrl } from '../../../utils/cta'
import { usePersonaEvents } from '../../../events'
import { ROW_TRACKING } from '../utils'
import {
  TrackingSectionContainer,
  Image,
  GlowEffect,
} from '../../../components'
import { MasterClass } from '../MasterClass'
import { useNonCommerceGoldStandardEvents } from '../../../hooks/tracking/useNonCommerceGoldStandardEvents'
import type { Locations } from '../../../types/tracking'

export const HeroSection = ({ ctaUrl }: { ctaUrl: string }) => {
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()
  const { t } = useTranslation('@mc/persona')

  const { trackPersonaSignClick } = usePersonaEvents()
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents('discovery')

  const handleCTAClick = () => {
    const pageSource = 'persona landing page'
    const trackProps = {
      cta: 'sign up',
      location: ROW_TRACKING.hero.rowName as Locations,
    }
    trackPersonaSignClick({
      ...trackProps,
      additionalAnalytics: { pageSource },
    })
    trackPurchaseClick({ ...trackProps, pageSource })
  }

  return (
    <TrackingSectionContainer analytics={ROW_TRACKING.hero}>
      <div className='container mc-py-9'>
        <div className='row'>
          <div className='col-12 col-md-6 mc-px-10 mc-px-sm-12 mc-p-md-8 position-relative'>
            <div
              className='mc-w-100 mc-h-100 position-relative'
              style={{ zIndex: 1 }}
            >
              <Image
                className='position-relative mc-w-100 mc-h-100 object-fit-contian'
                src='https://static.masterclass.com/persona-pre-pw-logged-out-hero-v3.webp'
                alt={t('landing.hero.imageAlt')}
                priority
                width={491}
                height={633}
              />
            </div>
            <GlowEffect />
          </div>
          <div className='col-12 col-md-6 mc-text--center mc-px-8 mc-px-sm-12 mc-px-md-10 d-flex flex-column justify-content-center align-items-center'>
            <h1 className='mc-font-headline2 mc-my-4'>
              {t('landing.hero.title')}
            </h1>
            <p
              className='mc-text-large'
              style={{ whiteSpace: 'pre-wrap', opacity: 0.8 }}
            >
              {t('landing.hero.subtitle')}
            </p>
            <Button
              className='mc-mt-9 mc-px-11 mc-py-4 mc-corners--12'
              as={Link}
              onClick={handleCTAClick}
              href={getCtaToPurchaseUrl(ctaUrl, 'discovery')}
            >
              {t('landing.hero.cta')}
            </Button>
            <MasterClass className='d-none d-md-flex' />
          </div>
        </div>
        <MasterClass className='d-flex d-md-none' />
      </div>
    </TrackingSectionContainer>
  )
}
