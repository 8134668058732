import React, { useRef, useEffect, useState } from 'react'
import Link from 'next/link'
import { chunk } from 'lodash'
import {
  motion,
  useMotionValue,
  animate,
  AnimationPlaybackControls,
} from 'framer-motion'

import { useTranslation } from '@mc/i18n'
import { isMobile } from '@mc/devices'
import { Button } from '@mc/design-system'
import { TrackingContainer } from '@mc/visibility'
import { usePersonaEvents } from '../../events'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'
import { useCtaToPurchaseUrl } from '../../utils/cta'
import { Locations } from '../../types/tracking'
import { TrackingSectionContainer } from '../TrackingSectionContainer'
import styles from './UseCaseSection.module.scss'

const DESKTOP_SCROLL_SPEED = 50
const MOBILE_SCROLL_SPEED = 90

export type Topic = {
  text: { displayText: string; caption: string }
  analytics?: Record<string, string | number | undefined>
  redirectUrl: string
}

const TopicItem = ({
  topic,
  index,
  rowAnalytics,
}: {
  topic: Topic
  index: number
  rowAnalytics: { rowName: string; rowPositionIndex: number }
}) => {
  const { trackPersonaClick, trackPersonaRowTileClicked } = usePersonaEvents()
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents('discovery')

  return (
    <Button
      as={Link}
      href={topic.redirectUrl}
      kind='secondary'
      className={`mc-bg-neutral-900 d-flex flex-row flex-none mc-py-2 mc-px-8 ${styles.item}`}
      onClick={() => {
        if (!topic || !topic.analytics) return

        trackPersonaRowTileClicked({
          ...rowAnalytics,
          tilePositionIndex: index,
          tileTitle: topic.analytics.cta as string,
          tileSubtitle: topic.text.caption as string,
          tileLink: topic.redirectUrl,
          tileInstructorSlug: topic.analytics.instructorSlug as string,
        })
        trackPersonaClick({
          additionalAnalytics: { ...topic.analytics },
        })
        trackPurchaseClick({
          pageSource: 'persona landing page',
          cta: topic.text.caption,
          location: 'conversation starters',
        })
      }}
    >
      <div className='d-flex flex-column justify-content-center mc-px-1mc-gap-1'>
        <p className='mc-text-h6'>{topic.text.displayText}</p>
      </div>
    </Button>
  )
}

const ScrollingTopicRow = ({
  topics,
  pauseScroll,
  speedOffset,
  subRowIndex,
  rowAnalytics,
}: {
  topics: Topic[]
  pauseScroll: boolean
  speedOffset: number
  subRowIndex: number
  rowAnalytics: {
    rowName: string
    rowPositionIndex: number
  }
}) => {
  const { trackPersonaRowTileViewed } = usePersonaEvents()
  const controlsRef = useRef<AnimationPlaybackControls | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const xTranslation = useMotionValue(0)

  useEffect(() => {
    if (pauseScroll) {
      controlsRef.current?.pause()
    } else {
      controlsRef.current?.play()
    }
  }, [pauseScroll])

  useEffect(() => {
    const width = containerRef.current?.scrollWidth ?? 0
    const finalPosition = -width / 2 - 8 // 8 compensates for gap which is 16px (mc-gap-4)
    const scrollSpeed = isMobile() ? MOBILE_SCROLL_SPEED : DESKTOP_SCROLL_SPEED
    const duration = width / (scrollSpeed + speedOffset * (scrollSpeed / 5))

    const controls = animate(xTranslation, [0, finalPosition], {
      ease: 'linear',
      duration,
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
    })
    controlsRef.current = controls

    return () => {
      controls.stop()
      controlsRef.current = null
    }
  }, [xTranslation, speedOffset])

  const items = [...topics, ...topics]

  return (
    <motion.div
      ref={containerRef}
      className='d-flex mc-gap-4 mc-mb-4'
      style={{ left: 0, x: xTranslation }}
    >
      {items.map((topic, i) => (
        <TrackingContainer
          key={i}
          config={{ threshold: 0.3 }}
          className='d-flex flex-row flex-none'
          onVisible={() =>
            trackPersonaRowTileViewed({
              ...rowAnalytics,
              subrowPositionIndex: subRowIndex.toString(),
              tilePositionIndex: i.toString(),
              tileTitle: topic.text.displayText,
              tileSubtitle: topic.text.caption,
              tileInstructorSlug:
                (topic.analytics?.instructorSlug as string | undefined) || '',
            })
          }
        >
          <TopicItem topic={topic} index={i} rowAnalytics={rowAnalytics} />
        </TrackingContainer>
      ))}
    </motion.div>
  )
}

type UseCaseSectionProps = {
  topics: Topic[]
  ctaUrl: string
  rowAnalytics: {
    rowName: string
    rowPositionIndex: number
  }
}

export const UseCaseSection = ({
  topics,
  ctaUrl,
  rowAnalytics,
}: UseCaseSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const [pauseScroll, setPauseScroll] = useState(false)
  const itemsRows = chunk(topics, Math.ceil(topics.length / 3))
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()
  const { trackPersonaSignClick } = usePersonaEvents()
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents('discovery')

  const handleCTAClick = () => {
    const pageSource = 'persona landing page'
    const trackProps = {
      cta: 'sign up',
      location: rowAnalytics.rowName as Locations,
    }
    trackPersonaSignClick({
      ...trackProps,
      additionalAnalytics: { pageSource },
    })
    trackPurchaseClick({ ...trackProps, pageSource })
  }

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='mc-py-10 uncontainer' style={{ overflow: 'hidden' }}>
        <h2 className='mc-text-h1 mc-text--center mc-mb-10'>
          {t('landing.useCase.title')}
        </h2>
        <motion.div
          onHoverStart={() => setPauseScroll(true)}
          onHoverEnd={() => setPauseScroll(false)}
          onTouchStart={() => setPauseScroll(true)}
          onTouchEnd={() => setPauseScroll(false)}
        >
          {itemsRows.map((itemsRow, rowIndex) => (
            <ScrollingTopicRow
              key={rowIndex}
              topics={itemsRow}
              pauseScroll={pauseScroll}
              speedOffset={rowIndex}
              subRowIndex={rowIndex}
              rowAnalytics={rowAnalytics}
            />
          ))}
        </motion.div>
        <div className='mc-text--center'>
          <Button
            className='mc-mt-9 mc-px-11 mc-py-4 mc-corners--12'
            as={Link}
            href={getCtaToPurchaseUrl(ctaUrl, 'discovery')}
            onClick={handleCTAClick}
          >
            {t('landing.plans.cta')}
          </Button>
        </div>
      </div>
    </TrackingSectionContainer>
  )
}
