import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { PROP_TYPE_CHILDREN } from '../constants'
import { AspectRatio, ASPECT_RATIOS } from '../helpers'

export interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  aspectRatio?: AspectRatio
  className?: string
  crop?: boolean
  innerRef?: React.Ref<HTMLDivElement>
  onClick?: React.MouseEventHandler
}

class TileImpl extends PureComponent<TileProps> {
  static propTypes = {
    aspectRatio: PropTypes.oneOf([...ASPECT_RATIOS]),
    children: PROP_TYPE_CHILDREN,
    className: PropTypes.string,
    crop: PropTypes.bool,
    innerRef: PropTypes.oneOfType([
      PropTypes.func,
      typeof Element === 'undefined'
        ? PropTypes.any
        : PropTypes.instanceOf(Element),
    ]),
  }

  static defaultProps = {
    aspectRatio: '16x9',
  }

  render() {
    const { aspectRatio, children, className, crop, innerRef, ...restProps } =
      this.props

    const classes = cn({
      [className ?? '']: className,
      'mc-tile': true,
      [`mc-tile--${aspectRatio}`]: aspectRatio,
      'mc-tile--crop': crop,
      'mc-tile--pointer': typeof restProps.onClick === 'function',
    })

    return (
      <div className={classes} ref={innerRef} {...restProps}>
        {children && <div className='mc-tile__content content'>{children}</div>}
      </div>
    )
  }
}

const Tile = React.forwardRef<HTMLDivElement, TileProps>((props, ref) => (
  <TileImpl {...props} innerRef={ref} />
))

Tile.displayName = 'Tile'

export default Tile
