import React from 'react'

import { TrackingSectionContainer } from '../../components'
import { ROW_TRACKING } from './utils'
import { MasterClass as MasterClassComponent } from '../../components/MasterClass'

export const MasterClass = ({
  className = 'd-flex',
}: {
  className?: string
}) => (
  <TrackingSectionContainer
    className={className}
    analytics={ROW_TRACKING.broughtToYouBy}
  >
    <MasterClassComponent />
  </TrackingSectionContainer>
)
