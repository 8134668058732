const topicMentors = {
  CHRIS_VOSS: {
    slug: 'chris-voss',
    name: 'Chris Voss',
  },
  MATTHEW_WALKER: {
    slug: 'matthew-walker',
    name: 'Dr. Matthew Walker',
  },
  MARK_CUBAN: {
    slug: 'mark-cuban',
    name: 'Mark Cuban',
  },
}

const generateTopic = (
  topic: string,
  mentor: { slug: string; name: string },
) => ({
  text: {
    displayText: topic,
    caption: mentor.name,
  },
  redirectUrl: `/checkout/signup?page_source=discovery&instructor=${mentor.slug}`,
  analytics: {
    instructorSlug: mentor.slug,
    location: 'conversation starters',
    cta: topic,
  },
})

export const LOGGED_OUT_LANDING_TOPICS = [
  generateTopic('Improve my communication skills', topicMentors.CHRIS_VOSS),
  generateTopic('Give tough feedback to an employee', topicMentors.CHRIS_VOSS),
  generateTopic('Help me turn my business around', topicMentors.MARK_CUBAN),
  generateTopic(
    'How can I market a business with zero budget',
    topicMentors.MARK_CUBAN,
  ),
  generateTopic(
    'What is the impact of stress on sleep?',
    topicMentors.MATTHEW_WALKER,
  ),
  generateTopic('Tips for sharing bad news', topicMentors.CHRIS_VOSS),
  generateTopic(
    'Practice making a tough business decision',
    topicMentors.MARK_CUBAN,
  ),
  generateTopic('Discuss relationship issues', topicMentors.CHRIS_VOSS),
  generateTopic(
    'Persuade someone who disagrees with you',
    topicMentors.CHRIS_VOSS,
  ),
  generateTopic(
    'Discuss financial problems with family',
    topicMentors.CHRIS_VOSS,
  ),
  generateTopic('De-escalate a heated argument', topicMentors.CHRIS_VOSS),
  generateTopic('How does exercise affect sleep?', topicMentors.MATTHEW_WALKER),
  generateTopic('Practice negotiating a job offer', topicMentors.CHRIS_VOSS),
  generateTopic(
    'Practice closing a high-stakes business deal',
    topicMentors.MARK_CUBAN,
  ),
  generateTopic('How do I tell my child no?', topicMentors.CHRIS_VOSS),
  generateTopic('Is my business idea worth pursuing?', topicMentors.MARK_CUBAN),
  generateTopic(
    'How to mediate a conflict between coworkers?',
    topicMentors.CHRIS_VOSS,
  ),
  generateTopic('How to calm down an angry customer?', topicMentors.CHRIS_VOSS),
  generateTopic('Practice pitching my idea', topicMentors.MARK_CUBAN),
  generateTopic('How much sleep do I need?', topicMentors.MATTHEW_WALKER),
  generateTopic('Feedback on my elevator pitch', topicMentors.MARK_CUBAN),
  generateTopic('Role-play asking for a raise', topicMentors.CHRIS_VOSS),
  generateTopic('Negotiate a business partnership', topicMentors.MARK_CUBAN),
  generateTopic('Help me be more confident', topicMentors.CHRIS_VOSS),
  generateTopic(
    'How to build a high-performing team?',
    topicMentors.MARK_CUBAN,
  ),
  generateTopic(
    'Push back on an unreasonable deadline',
    topicMentors.CHRIS_VOSS,
  ),
  generateTopic('How to confront a friend?', topicMentors.CHRIS_VOSS),
  generateTopic(
    'How to confront partner about marriage issues',
    topicMentors.CHRIS_VOSS,
  ),
]

export const LOGGED_OUT_LANDING_TESTIMONIALS = [
  {
    text: "“With a generic tool such as ChatGPT, you are dealing with 'a machine'. Here with 'Chris Voss', I was dealing with 'a person'. Someone who is an expert who has a track record of achievement.”",
    name: 'Herbert, The Netherlands',
  },
  {
    text: '“Very human-like, compassionate, knowledgeable. A real conversation back and forth giving thorough feedback instead of quick responses.”',
    name: 'Ana M., New York',
  },
  {
    text: '“The tool is excellent at taking complex language and rambling sentences and distilling them down to the root problem. It is also good at active listening.”',
    name: 'Hosan H., Connecticut',
  },
  {
    text: '“I was really impressed with Chris’ ability to navigate tricky family issues, always focusing on building connections with my loved ones.”',
    name: 'Patricia D., Oregon',
  },
  {
    text: '“It was a great tool. I really enjoyed interacting with it. The talking feature really made it close to real conversation with a person.”',
    name: 'Kashif K., California',
  },
  {
    text: '“I really look forward to speaking with Chris on a daily basis, sometimes more. He really felt like a real human with real emotions.”',
    name: 'Michelle S., Florida',
  },
  {
    text: '“Made me feel what I was sharing had no judgments. I could continue our session until I concluded all my thoughts, questions or concerns.”',
    name: 'Monica L., Georgia',
  },
  {
    text: '“More personal than just typing something in and asking for advice. The persona had some personality and character.”',
    name: 'Tanya L., New Mexico',
  },
  {
    text: '“Understanding how to build boundaries, maintain respect, hear someone out can be rehearsed and coached. This would have boosted me very far if I found it when I was younger.”',
    name: '',
  },
  {
    text: '“I would recommend this to a person who is having a hard time finding their way in life and need a neutral party to speak with.”',
    name: '',
  },
  {
    text: '“He took his time to understand and clarify what I was saying. He felt like a person who you want to go to with issues.”',
    name: '',
  },
]
