import { GetServerSideProps } from 'next'

import { LoggedOutLandingPage, paths } from '@mc/persona-client'

import { LOGGED_OUT_LANDING_TESTIMONIALS } from './mocks/loggedOutLanding'
import { getOrRefreshAccessToken } from './utils/getOrRefreshAccessToken'

const LoggedOutHome = () => (
  <LoggedOutLandingPage testimonials={LOGGED_OUT_LANDING_TESTIMONIALS} />
)

LoggedOutHome.pageSettings = {
  title: 'MasterClass On Call',
  // This is the name of the page for tracking purposes, no need to send page to track events
  pageName: 'persona landing page',
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  // Redirect the user if exists to the /homepage
  const session = await getOrRefreshAccessToken({
    req: ctx.req,
    res: ctx.res,
  })

  if (session) {
    return {
      redirect: {
        destination: paths.homepage,
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}

export default LoggedOutHome
